import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
           <path d="M300 2037 c0 -11 18 -15 79 -19 84 -5 130 -24 163 -68 18 -24 18 -57
18 -757 0 -720 0 -732 -20 -766 -28 -48 -75 -68 -165 -70 -52 -1 -75 -6 -75
-14 0 -10 62 -13 275 -13 247 0 275 2 275 16 0 12 -7 15 -26 11 -52 -10 -154
10 -181 35 -53 50 -53 47 -53 477 l0 400 23 3 c16 2 22 10 22 28 0 19 -5 25
-22 24 l-23 -1 -2 276 c0 152 0 284 1 295 1 19 -48 106 -61 106 -5 0 -8 4 -8
8 0 4 49 9 109 11 108 3 109 2 136 -26 14 -15 33 -41 42 -58 9 -16 28 -45 42
-64 14 -19 40 -56 56 -82 53 -84 126 -188 136 -195 30 -18 6 24 -127 225
l-147 221 -218 0 c-119 0 -224 3 -233 6 -10 4 -16 1 -16 -9z m320 -1669 c0 -5
-20 -8 -45 -8 -25 0 -45 2 -45 5 0 3 10 19 22 36 l23 30 22 -28 c13 -15 23
-31 23 -35z"/>
<path d="M1414 2041 c-13 -14 5 -19 79 -23 99 -5 139 -25 167 -83 18 -36 20
-62 20 -212 l0 -172 -57 -7 c-66 -8 -242 0 -451 21 -253 26 -424 17 -521 -26
-61 -27 -44 -35 22 -11 30 12 74 22 99 24 l44 3 402 -601 c220 -330 408 -606
416 -613 13 -11 16 -11 16 0 0 13 -70 122 -82 127 -5 2 -8 8 -8 13 0 5 -22 39
-48 77 -27 37 -51 72 -53 77 -5 13 -162 249 -169 255 -3 3 -17 23 -30 45 -13
22 -45 69 -71 105 -26 35 -45 68 -43 72 3 4 1 8 -3 8 -5 0 -17 15 -28 33 -10
17 -23 37 -27 42 -15 18 -178 265 -178 270 0 5 -22 31 -63 73 l-22 22 84 0
c46 0 181 -9 299 -21 133 -13 264 -19 344 -17 127 3 128 3 128 -19 0 -22 -4
-23 -85 -23 -52 0 -85 -4 -85 -10 0 -6 6 -10 13 -9 85 11 147 -31 147 -100 0
-30 -4 -39 -13 -35 -18 7 -39 -20 -31 -40 3 -9 15 -16 25 -16 18 0 19 -14 21
-277 2 -177 7 -278 13 -278 6 0 10 95 10 266 0 211 -3 271 -14 292 -12 23 -12
31 -1 51 8 17 10 38 5 63 -4 21 -7 46 -8 55 -1 9 -5 14 -9 12 -4 -3 -8 0 -8 6
0 9 15 10 53 4 28 -4 61 -8 72 -9 29 -2 31 -46 25 -637 l-5 -513 -68 -3 c-41
-2 -74 2 -82 8 -10 8 -19 8 -33 1 -16 -9 -66 -18 -110 -20 -7 -1 -11 -4 -9 -8
3 -5 146 -8 319 -7 281 1 320 3 379 21 167 50 275 156 336 329 26 74 27 89 28
254 0 159 -2 181 -24 245 -48 140 -128 238 -241 295 -101 51 -170 61 -397 61
l-203 0 0 24 c0 18 10 30 38 44 20 10 45 33 55 49 21 37 23 82 2 82 -10 0 -15
-10 -15 -31 0 -20 -10 -40 -29 -60 -55 -54 -57 -48 -50 152 6 159 9 184 26
207 34 46 79 65 164 70 49 3 79 9 79 16 0 8 -79 11 -276 11 -152 0 -278 -2
-280 -4z m326 -34 c0 -8 -11 -24 -25 -37 l-26 -24 -23 29 c-13 17 -22 33 -19
37 8 14 93 9 93 -5z m395 -561 c173 -52 254 -230 255 -558 0 -372 -92 -546
-310 -586 -28 -5 -93 -10 -145 -11 l-95 -2 0 592 1 592 122 -6 c67 -3 145 -13
172 -21z m165 -55 c0 -5 10 -11 23 -15 32 -8 95 -70 135 -133 68 -107 98 -240
89 -402 -12 -220 -87 -375 -221 -460 -27 -17 -52 -29 -54 -26 -2 2 11 23 31
47 32 40 52 88 88 208 10 32 14 110 14 270 0 240 -7 294 -51 381 -13 26 -20
50 -16 54 4 5 2 5 -4 2 -6 -3 -20 14 -32 38 -12 25 -17 45 -12 45 6 0 10 -4
10 -9z"/>
<path d="M1919 1314 c-12 -14 -10 -19 10 -35 28 -23 54 -9 49 25 -4 26 -40 33
-59 10z"/>
<path d="M2097 1323 c-10 -9 -8 -51 2 -57 5 -3 19 3 32 13 20 16 21 21 10 35
-14 17 -33 21 -44 9z"/>
<path d="M2241 1316 c-17 -20 -3 -46 25 -46 19 0 24 5 24 24 0 13 -7 27 -16
30 -21 8 -20 8 -33 -8z"/>
<path d="M617 1859 c10 -17 50 -79 90 -138 48 -72 68 -108 60 -113 -7 -4 -31
-10 -55 -14 -23 -3 -45 -11 -48 -16 -4 -6 1 -8 12 -4 42 12 112 24 159 25 60
3 75 11 19 11 -48 0 -63 9 -83 51 -22 46 -92 153 -103 157 -4 2 -8 9 -8 15 -1
17 -36 57 -50 57 -7 0 -4 -12 7 -31z"/>
<path d="M1825 1610 c-4 -17 -22 -43 -42 -59 -27 -23 -31 -29 -15 -24 29 7 82
68 82 93 0 31 -18 24 -25 -10z"/>
<path d="M1090 1521 c0 -6 31 -57 68 -114 38 -56 128 -191 200 -299 73 -109
132 -202 132 -208 0 -5 4 -10 8 -10 5 0 29 -31 53 -70 24 -38 47 -70 51 -70 5
0 8 -6 8 -14 0 -7 12 -22 27 -31 l26 -18 -23 39 c-62 101 -519 781 -533 793
-11 9 -17 10 -17 2z"/>
<path d="M424 1315 c-8 -21 2 -44 22 -52 24 -9 59 34 43 53 -15 19 -58 18 -65
-1z"/>
<path d="M742 1318 c-16 -16 -15 -23 3 -42 28 -27 69 10 44 40 -14 17 -31 18
-47 2z"/>
<path d="M892 1321 c-21 -13 -10 -46 16 -49 27 -4 40 22 22 43 -14 17 -20 18
-38 6z"/>
<path d="M1341 1317 c-13 -17 -6 -43 14 -51 18 -6 38 18 33 41 -4 23 -31 28
-47 10z"/>
<path d="M1475 1316 c-24 -17 -13 -46 19 -46 27 0 42 26 25 46 -14 17 -20 17
-44 0z"/>
<path d="M2526 1315 c-20 -21 -9 -45 20 -45 29 0 41 24 24 45 -16 19 -25 19
-44 0z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
